import React, {useState} from 'react';
import CreatableReactSelect from 'react-select/creatable';
import {WrappedFieldProps} from 'redux-form';

import TooltipBootstrap from 'components/ui/TooltipBootstrap';

import styles from './styles.module.scss';

export type OptionTag = {label: string; value: unknown};
export type GroupTag = {label: string; options: OptionTag[]};

type Action = {
    action: 'select-option';
    option: OptionTag;
    name?: string;
};

type OwnProps = {
    isValidNewOption?: (inputValue: string, value: OptionTag | GroupTag) => boolean;
    getNewOptionData?: (inputValue: string) => void;
    options: OptionTag[] | GroupTag[];
    input: WrappedFieldProps['input'];
    meta: WrappedFieldProps['meta'];
    closeMenuOnSelect?: boolean;
    isClearable?: boolean;
    isCreatable?: boolean;
    isDisabled?: boolean;
    placeholder?: string;
    maxTagCount?: number;
    isMulti?: boolean;
    label?: string;
    name: string;
};

const CreatableMultiselect: React.FC<OwnProps> = (props) => {
    const {
        closeMenuOnSelect,
        isValidNewOption,
        getNewOptionData,
        maxTagCount,
        isClearable,
        isCreatable,
        placeholder,
        isDisabled,
        isMulti,
        options,
        input,
    } = props;

    const [selectedOptions, setSelectedOptions] = useState<OptionTag[]>(input.value || []);
    const [hiddenTags, setHiddenTags] = useState<OptionTag[]>(selectedOptions?.slice(Number(maxTagCount)) || []);
    const [hiddenTagsCount, setHiddenTagsCount] = useState(Math.max(selectedOptions?.length - Number(maxTagCount), 0));

    const handleChange = (selectedOptionsWithNewTag, action: Action): void => {
        input.onChange(selectedOptionsWithNewTag);

        setSelectedOptions(selectedOptionsWithNewTag);

        const isRemovingElement = selectedOptions?.find((option) => !selectedOptionsWithNewTag?.includes(option));

        if (isRemovingElement && selectedOptionsWithNewTag?.length >= Number(maxTagCount)) {
            setHiddenTagsCount((prev) => prev - 1);
            setHiddenTags(hiddenTags?.slice(1));

            return;
        }

        if (selectedOptionsWithNewTag?.length > Number(maxTagCount)) {
            setHiddenTags((prev) => [...prev, action.option]);
            setHiddenTagsCount((prev) => prev + 1);
        }
    };

    const handleClearHiddenTags = () => {
        const selectedOpts = selectedOptions?.slice(0, -hiddenTags.length);

        input.onChange(selectedOpts);

        setSelectedOptions(selectedOpts);

        setHiddenTags([]);
        setHiddenTagsCount(0);
    };

    const MultiValue = (multiValueState) => {
        const {data, index, removeProps} = multiValueState;

        if (index >= Number(maxTagCount)) {
            return null;
        }

        return (
            <div className={styles.tag}>
                <div className={styles.tag__label}>{data.label}</div>

                <div className={styles.tag__remove} {...removeProps}>
                    &times;
                </div>
            </div>
        );
    };

    const ClearIndicator = (clearIndicatorState) => {
        const {
            innerProps: {ref, ...restInnerProps},
        } = clearIndicatorState;

        const handleClear = () => {
            setHiddenTags([]);
            setHiddenTagsCount(0);
        };

        return (
            <div {...restInnerProps} ref={ref}>
                <div onMouseDown={handleClear} className={styles.clear}>
                    <i className="fa fa-times" aria-hidden="true" />
                </div>
            </div>
        );
    };

    const tooltipContent = <div className="maxw300">{hiddenTags?.map((item) => item?.label).join(', ')}</div>;

    return (
        <div className={styles.select}>
            <CreatableReactSelect
                closeMenuOnSelect={closeMenuOnSelect}
                isValidNewOption={isValidNewOption}
                getNewOptionData={getNewOptionData}
                value={input.value || null}
                isClearable={isClearable}
                placeholder={placeholder}
                isCreatable={isCreatable}
                onChange={handleChange}
                isDisabled={isDisabled}
                valueAsArray={true}
                options={options}
                isMulti={isMulti}
                components={{
                    ClearIndicator,
                    MultiValue,
                }}
            />

            {hiddenTagsCount > 0 ? (
                <div className={styles.tag__count}>
                    <TooltipBootstrap tooltipContent={tooltipContent}>
                        <span>+{hiddenTagsCount}...</span>

                        <div className="ml15" />

                        <div onClick={handleClearHiddenTags} className={styles.tag__remove}>
                            &times;
                        </div>
                    </TooltipBootstrap>
                </div>
            ) : null}
        </div>
    );
};

export default CreatableMultiselect;
