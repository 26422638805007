import {change, destroy} from 'redux-form';

import Owner from 'core/entities/Owner/types';

import {countriesOfBirth} from 'utils/data/countries';
import {getCityLineFromObject} from 'utils/getCityLine';

import {CREATE_DRIVER_FORM_NAME} from '../constants';
import {FIELDS} from '../constants/fields';
import {getInitialDataForCreateDriver} from '../selectors';

const copyOwnerFieldsToDriverForm = (formName: string, owner: Owner) => {
    return function (dispatch) {
        const mapOwnerFields = {
            email: owner.email,
            language: owner.language,
            full_name: owner.full_name,
            date_of_birthday: owner.date_of_birthday,
            country_of_birth: {label: countriesOfBirth[owner.country_of_birth].name, value: owner.country_of_birth},
            address_line: owner.address_line,
            additional_address_line: owner.additional_address_line,
            citizenship: owner.citizenship,
            city: owner.city,
            state: owner.state,
            zip: owner.zip,
            country: owner.country,
            [FIELDS.custom.driverHomeLocation]: getCityLineFromObject({
                city: owner.city,
                state: owner.state,
                zip: owner.zip,
                country: owner.country,
            }),
            [FIELDS.contact.home_lat]: owner.home_lat,
            [FIELDS.contact.home_lng]: owner.home_lng,
            [FIELDS.general.is_owner]: true,
            home_phone: owner.home_phone,
            mobile_phone: owner.mobile_phone,
            additional_mobile_phone: owner.additional_mobile_phone,
            ec_name: owner.ec_name,
            relationship: owner.relationship,
            ec_phone: owner.ec_phone,
            owner: {
                full_name: owner.full_name,
                home_lat: owner.home_lat,
                home_lng: owner.home_lng,
                id: owner.id,
                language: owner.language,
            },
        };
        Object.keys(mapOwnerFields).forEach((item) => dispatch(change(formName, item, mapOwnerFields[item])));
    };
};

export function setDriverCreateInitialValues(): any {
    return function (dispatch, getState): any {
        const state = getState();
        const currentDriverInitialData = getInitialDataForCreateDriver(state);
        if (currentDriverInitialData.ownerFields) {
            dispatch(copyOwnerFieldsToDriverForm(CREATE_DRIVER_FORM_NAME, currentDriverInitialData.ownerFields));
            return;
        }

        if (currentDriverInitialData.workingWith) {
            dispatch(change(CREATE_DRIVER_FORM_NAME, FIELDS.general.owner, currentDriverInitialData.workingWith));
        }
    };
}

export function chooseOwner(formName: string, ownerSettings: {driverIsOwner: boolean; owner: Owner | null}): any {
    return function (dispatch): any {
        const {driverIsOwner, owner} = ownerSettings;
        dispatch(change(formName, FIELDS.general.is_owner, driverIsOwner));
        dispatch(change(formName, FIELDS.general.owner, owner));

        if (driverIsOwner && owner) {
            dispatch(copyOwnerFieldsToDriverForm(formName, owner));
        }
    };
}

export function destroyForm(formName: string): any {
    return function (dispatch): any {
        dispatch(destroy(formName));
    };
}
