import React from 'react';
import {Field} from 'redux-form';

import {fetchSenders as fetchSendersRequest} from 'core/entities/Sender/requests';
import {SenderType} from 'core/entities/Sender/types';

import SingleAsyncSelect from 'components/ui/Form/SingleAsyncSelect';

import {getSendersOptions} from './utils';

const MIN_ITEMS_TO_HAS_MORE = 8;

type OwnProps = {
    closeMenuOnSelect?: boolean;
    isFetchOnMount?: boolean;
    errorPlacement?: string;
    senderType: SenderType;
    isClearable?: boolean;
    isDisabled?: boolean;
    placeholder?: string;
    label?: string;
    name: string;
};

const SingleSendersByTypeAsyncSelect: React.FC<OwnProps> = (props) => {
    const {
        placeholder = 'choose sender',
        closeMenuOnSelect = true,
        isFetchOnMount = false,
        isClearable = false,
        isDisabled = false,
        errorPlacement,
        senderType,
        label,
        name,
    } = props;

    const loadOptions = async (_, __, {page}) => {
        try {
            const {data} = await fetchSendersRequest();
            const sendersOptions = getSendersOptions({sendersList: data, senderType});

            return {
                options: sendersOptions,
                hasMore: sendersOptions.length > MIN_ITEMS_TO_HAS_MORE,
                additional: {page: page + 1},
            };
        } catch (error) {
            return {
                options: [],
                hasMore: false,
            };
        }
    };

    return (
        <>
            {label ? <label className="form-label">{label}</label> : null}

            <Field
                closeMenuOnSelect={closeMenuOnSelect}
                isFetchOnMount={isFetchOnMount}
                errorPlacement={errorPlacement}
                component={SingleAsyncSelect}
                loadOptions={loadOptions}
                isClearable={isClearable}
                placeholder={placeholder}
                isDisabled={isDisabled}
                name={name}
            />
        </>
    );
};

export default SingleSendersByTypeAsyncSelect;
