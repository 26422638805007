import React, {useEffect, useState} from 'react';
import ReactSelect, {components} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import classNames from 'classnames';
import head from 'lodash/head';
import {WrappedFieldProps} from 'redux-form';

import getValidationState from 'components/ui/Form/getValidationState';
import OverlayTriggerCustom from 'components/ui/Form/OverlayTriggerCustom';
import {Option, SingleSelectProps} from 'components/ui/Form/SingleSelect/types';

import useChangeReduxFormValue from 'hooks/reduxForm/useChangeReduxFormValue';
import useClearReduxFormValue from 'hooks/reduxForm/useClearReduxFormValue';

import styles from './styles.module.scss';

const OptionWithIcon = (props) => {
    const {
        data: {label, icon},
    } = props;

    return (
        <components.Option {...props}>
            <span>{label}</span>&nbsp;
            {icon ? <i className={`fa ${icon}`} aria-hidden="true" /> : null}
        </components.Option>
    );
};

const SingleValueWithIcon = (props) => {
    const {
        data: {label, icon},
    } = props;

    return (
        <components.SingleValue {...props}>
            <span>{label}</span>&nbsp;
            {icon ? <i className={`fa ${icon}`} aria-hidden="true" /> : null}
        </components.SingleValue>
    );
};

const Select: React.FC<SingleSelectProps & WrappedFieldProps> = (props) => {
    const {
        options: initialOptions,
        isFirstOptionSelected,
        closeMenuOnSelect,
        externalOnChange,
        formatGroupLabel,
        errorPlacement,
        isCreatable,
        isClearable,
        placeholder,
        isDisabled,
        input,
        meta,
    } = props;

    const [options, setOptions] = useState<Option[] | undefined>(initialOptions);
    const changeValue = useChangeReduxFormValue(meta.form);
    const clearValue = useClearReduxFormValue(meta.form);

    const createOption = (label: string) => ({label, value: label.toLowerCase().replace(/\W/g, '')});

    const validationState = getValidationState(meta);

    const handleChange = (option: Option | null) => {
        if (externalOnChange) {
            externalOnChange(option);
            return;
        }

        if (!option && meta.initial) {
            changeValue(input.name, null);
            return;
        }

        if (!option) {
            changeValue(input.name, null);
            clearValue(input.name);
            return;
        }

        changeValue(input.name, option);
    };

    const handleCreateOption = (inputValue: string) => {
        const newOption = createOption(inputValue);

        setOptions((prev) => [...(prev ?? []), newOption]);

        handleChange(newOption);
    };

    const wrapClassNames = classNames(styles.wrap, {
        [styles.error]: !validationState,
        [styles.disabled]: isDisabled,
    });

    const getSelectValue = () => {
        if (input.value) {
            return input.value;
        }

        if (isFirstOptionSelected) {
            return head(options);
        }

        return null;
    };

    useEffect(() => {
        setOptions(initialOptions);
    }, [initialOptions?.length]);

    return (
        <OverlayTriggerCustom className={wrapClassNames} inputMeta={meta} placement={errorPlacement}>
            {isCreatable ? (
                <CreatableSelect
                    components={{Option: OptionWithIcon, SingleValue: SingleValueWithIcon}}
                    closeMenuOnSelect={closeMenuOnSelect}
                    onCreateOption={handleCreateOption}
                    formatGroupLabel={formatGroupLabel}
                    isClearable={isClearable}
                    placeholder={placeholder}
                    value={getSelectValue()}
                    onChange={handleChange}
                    isDisabled={isDisabled}
                    options={options}
                />
            ) : (
                <ReactSelect
                    components={{Option: OptionWithIcon, SingleValue: SingleValueWithIcon}}
                    closeMenuOnSelect={closeMenuOnSelect}
                    formatGroupLabel={formatGroupLabel}
                    isClearable={isClearable}
                    placeholder={placeholder}
                    value={getSelectValue()}
                    onChange={handleChange}
                    isDisabled={isDisabled}
                    options={options}
                />
            )}
        </OverlayTriggerCustom>
    );
};

export default Select;
