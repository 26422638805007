import chatApi from 'services/chatApi/http';

export const fetchGroups = ({page, perPage}) => {
    return chatApi.get(`groups/drivers?page=${page}&perPage=${perPage}`);
};

export const fetchGroupsByDispatcherID = ({page, perPage, dispatcherID}) => {
    return chatApi.get(`groups/drivers/by-dispatcher/${dispatcherID}?page=${page}&perPage=${perPage}`);
};

export const fetchMissingTruckNumbers = ({requestBody}) => {
    return chatApi.post('groups/drivers/missing-truck-numbers', requestBody);
};

export const searchDriversGroupRequest = ({cognitoUserId}) => {
    return chatApi.get(`groups/drivers/search?cognitoUserId=${cognitoUserId}`);
};

export const fetchAllUnreadMessages = ({dispatcherID}) => {
    return chatApi.get(`groups/drivers/messages/unread/all/dispatcher/${dispatcherID}`);
};

export const fetchUnreadMessagesByGroup = ({groupID, dispatcherID}) => {
    return chatApi.get(`groups/drivers/${groupID}/messages/unread/dispatcher/${dispatcherID}`);
};

export const fetchLatestMessagesByGroupAndMessageIDs = ({groupID, messageID, limit = '10'}) => {
    return chatApi.get(`groups/drivers/${groupID}/messages/latest/${messageID}?limit=${limit}`);
};

export const fetchLatestMessagesByGroupID = ({groupID, limit = '10'}) => {
    return chatApi.get(`groups/drivers/${groupID}/messages/latest?limit=${limit}`);
};

export const fetchAllGroupMessages = ({groupID}) => {
    return chatApi.get(`groups/drivers/${groupID}/messages/all`);
};

export const fetchChatDispatcherData = ({requestBody}) => {
    return chatApi.post('users/dispatcher/me', requestBody);
};

export const sendAttachments = ({requestBody}) => {
    return chatApi.post('attachments', requestBody);
};

export const markMessagesAsReadByEveryDispatcher = ({groupID}) => {
    return chatApi.put(`groups/drivers/${groupID}/messages/read-by-every-dispatcher`);
};

export const markMessagesAsReadByDispatcher = ({groupID, dispatcherID}) => {
    return chatApi.put(`groups/drivers/${groupID}/messages/read-by-dispatcher/${dispatcherID}`);
};
