import React, {ComponentProps} from 'react';
import {Field} from 'redux-form';

import normalizeEmails from 'components/ui/Form/EmailsSelect/normalizeEmails';

import * as regexps from 'utils/validation/regexps';

import CreatableMultiselect from '../MultiselectTagDropdown/components/CreatableMultiselect';

type SelectProps = 'input' | 'meta' | 'isValidNewOption' | 'getNewOptionData';
type OwnProps = Omit<ComponentProps<typeof CreatableMultiselect>, SelectProps>;

const MultiCreatableEmailsSelect: React.FC<OwnProps> = (props) => {
    const {label, placeholder = 'type one or more emails'} = props;

    const isValidNewOption = (inputValue) => {
        if (inputValue) {
            const normalizedValue = normalizeEmails(inputValue);
            const result = normalizedValue.split(',').map((value) => regexps.EMAIL.test(value));

            return result.every((element) => element === true);
        }

        return false;
    };

    const getNewOptionData = (value) => ({
        value: normalizeEmails(value),
        label: value,
    });

    return (
        <div className="d-flex flex-column">
            {label ? <label className="form-label">{label}</label> : null}

            <Field
                {...props}
                isMulti
                isCreatable
                closeMenuOnSelect={false}
                placeholder={placeholder}
                component={CreatableMultiselect}
                isValidNewOption={isValidNewOption}
                getNewOptionData={getNewOptionData}
            />
        </div>
    );
};

export default MultiCreatableEmailsSelect;
