import isEmpty from 'lodash/isEmpty';

import {IF_EMPTY_EMAIL, IF_EMPTY_SUBJECT, REQUIRED, IF_EMPTY_MESSAGE} from 'utils/validation/errorMessages';

import {BrokerUpdateSendFormValues} from '../../../types';

const getSendUpdateField = (field: keyof BrokerUpdateSendFormValues): string => field;

export default (values: any): any => {
    const allErrors = {};

    if (isEmpty(values)) {
        return {};
    }

    if (!values[getSendUpdateField('emailSender')]) {
        allErrors[getSendUpdateField('emailSender')] = IF_EMPTY_EMAIL;
    }

    if (!values[getSendUpdateField('emailsTo')]) {
        allErrors[getSendUpdateField('emailsTo')] = IF_EMPTY_EMAIL;
    }

    if (!values[getSendUpdateField('subject')]) {
        allErrors[getSendUpdateField('subject')] = IF_EMPTY_SUBJECT;
    }

    if (!values[getSendUpdateField('updateType')]) {
        allErrors[getSendUpdateField('updateType')] = REQUIRED;
    }

    if (!values[getSendUpdateField('message')]) {
        allErrors[getSendUpdateField('message')] = IF_EMPTY_MESSAGE;
    }

    return allErrors;
};
